import { action, observable } from 'mobx';

import { AuthClient } from '../client/interfaces';
import { UserDocument } from '../models/userDocument';
import { TokenHandler } from '../utils/tokenHandler';

export class UserStore {
  @observable userIdToken: string | null = null;

  @observable currentUser: UserDocument | null = null;

  @observable loginError: string | null = null;

  constructor(readonly authClient: AuthClient, readonly tokenHandler: TokenHandler) {
    this.tokenHandler = tokenHandler;
  }

  @action
  public async loginUser(email: string, password: string): Promise<void> {
    this.loginError = null;

    try {
      const { success, token } = await this.authClient.authenticate(email, password);
      if (success && token) {
        const user = await this.authClient.getCurrentUser();
        if (user) {
          this.loginError = null;
          this.currentUser = new UserDocument(user);
          this.userIdToken = await this.tokenHandler.getToken();
        }
      }
    } catch (err) {
      if (err.code === 'auth/too-many-requests') {
        this.loginError = 'Too many unsuccessful login attempts. Please try again later.';
      } else {
        this.loginError = 'The email address or password you entered is invalid.';
      }
    }
  }

  @action
  public async fetchCurrentUser(): Promise<void> {
    const token = this.tokenHandler.retrieveToken();
    if (token !== '' && this.currentUser === null) {
      const user = await this.authClient.getCurrentUser();
      if (user) {
        this.currentUser = new UserDocument(user);
        this.userIdToken = await this.tokenHandler.getToken();
      } else {
        this.currentUser = null;
        await this.tokenHandler.signOut();
      }
    }
  }

  @action
  public async removeCurrentUser(): Promise<void> {
    this.currentUser = null;
    await this.tokenHandler.signOut();
  }

  @action
  public resetLoginError(): void {
    this.loginError = null;
  }
}
