import { merge } from 'lodash';
import React, { useState } from 'react';

import { useStores } from '../../hooks/use-stores';
import { TenantDocument, TenantConfig } from '../../models';
import { ConfirmationModal } from '../confirmationModal/ConfirmationModal';
import { DialogTabbedForm } from '../formHelper/DialogTabbedForm';
import { BodyText, BoldBodyText } from '../modal/ModalHelper';

import { formFields } from './tenantConfigurationFormFields';

const defaultValues: TenantConfig = {
  deviceEncryptionIv: '',
  deviceEncryptionKey: '',
  devicePassword: '',
  deviceUpdateTimeout: 25,
  webhooks: [
    {
      apiKey: '',
      url: ''
    }
  ],
  iotConnector: {
    apiKey: '',
    baseUrl: ''
  },
  name: ''
};

const TenantConfiguration = ({
  open,
  tenant,
  editable = false,
  onClose
}: {
  open: boolean;
  tenant: TenantDocument;
  editable: boolean;
  onClose: () => void;
}): JSX.Element | null => {
  const { userStore, tenantStore } = useStores();
  const { currentUser } = userStore;
  const mergedData = merge({}, defaultValues, tenant);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [tenantConfig, setTenantConfig] = useState<TenantConfig | null>(defaultValues);

  return (
    <>
      <DialogTabbedForm
        fields={formFields(currentUser?.isAdmin() || false)}
        initialValues={mergedData}
        loading={false}
        onClose={onClose}
        onSubmit={async (config: TenantConfig): Promise<void> => {
          setTenantConfig((prevConfig) => ({
            ...prevConfig,
            ...config,
            webhooks: config.webhooks.filter((webhook) => webhook.apiKey || webhook.url)
          }));
          setShowConfirmModal(true);
        }}
        open={open}
        title={tenant ? `Settings for ${tenant.displayName}` : '-'}
        readonly={!editable}
      />
      {showConfirmModal && (
        <ConfirmationModal
          isOpen={showConfirmModal}
          validateInput={(inputText) => inputText === 'SAVE'}
          title='Really save Changes?'
          primaryButtonText='Save'
          primaryAction={async () => {
            if (tenantConfig) {
              await tenantStore.updateTenant(tenantConfig);
              setShowConfirmModal(false);
              onClose();
            }
          }}
          secondaryButtonText='Abort'
          onClose={() => setShowConfirmModal(false)}
        >
          <BodyText>
            Are you sure you want to save the changes? Be aware that incorrect settings result in
            malfunctions or connection loss of your fleet.
            <br />
            Please type <BoldBodyText>SAVE</BoldBodyText> to confirm.
          </BodyText>
        </ConfirmationModal>
      )}
    </>
  );
};

export { TenantConfiguration };
