import { config } from '../../utils/config';
import { TokenHandler } from '../../utils/tokenHandler';

import { AuthApiClient } from './authApiClient';
import { IOTApiClient } from './iotApiClient';

const tokenHandler = TokenHandler.getInstance();

export const authApiClient = new AuthApiClient(config.authApi, tokenHandler);
export const iotApiClient = new IOTApiClient(tokenHandler);
