import { initializeApp } from '@firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
  getIdToken,
  Auth,
  connectAuthEmulator,
  User
} from '@firebase/auth';

import configData from '../config/index.json';

import { IAuthHandler, ITokenStorage } from './interfaces';

export class TokenHandler implements ITokenStorage, IAuthHandler {
  private static instance: TokenHandler;

  private auth: Auth;

  private token: string | null = null;

  private readonly AUTH_TOKEN_KEY: string = 'authToken';

  private constructor() {
    this.auth = this.initializeFirebase();
    this.token = this.retrieveToken();
    this.setupAuthStateListener();
  }

  public static getInstance(): TokenHandler {
    if (!TokenHandler.instance) {
      TokenHandler.instance = new TokenHandler();
    }
    return TokenHandler.instance;
  }

  public retrieveToken(): string | null {
    return localStorage.getItem(this.AUTH_TOKEN_KEY) || null;
  }

  public async signInWithCustomToken(customToken: string): Promise<string> {
    try {
      const userCredential = await signInWithCustomToken(this.auth, customToken);
      this.token = await this.updateToken(userCredential.user);
      return this.token;
    } catch (error) {
      console.error('Error signing in with custom token:', error);
      throw error;
    }
  }

  public async getToken(): Promise<string> {
    if (!this.auth.currentUser) {
      return this.retrieveToken() || '';
    }
    try {
      this.token = await getIdToken(this.auth.currentUser);
      this.saveToken(this.token);
      return this.token;
    } catch (error) {
      console.error('Error getting token:', error);
      return '';
    }
  }

  public async signOut(): Promise<void> {
    try {
      await this.auth.signOut();
      this.clearToken();
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  }

  public saveToken(token: string): void {
    localStorage.setItem(this.AUTH_TOKEN_KEY, token);
  }

  public clearToken(): void {
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
    this.token = null;
  }

  private async updateToken(user: User): Promise<string> {
    try {
      const token = await getIdToken(user);
      this.saveToken(token);
      return token;
    } catch (error) {
      console.error('Error updating token:', error);
      throw error;
    }
  }

  private initializeFirebase(): Auth {
    if (!this.auth) {
      const { firebaseConfig } = configData;
      const auth = getAuth(initializeApp(firebaseConfig));

      if (firebaseConfig.authDomain === 'localhost') {
        connectAuthEmulator(auth, `http://${firebaseConfig.authEmulatorHost}`);
      }

      return auth;
    }
    return this.auth;
  }

  private setupAuthStateListener(): void {
    onAuthStateChanged(this.auth, async (user) => {
      if (user) {
        this.token = await this.updateToken(user);
        this.saveToken(this.token);
      } else {
        this.clearToken();
      }
    });
  }
}
