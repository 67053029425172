import { DEVICE_TYPES, UNDEFINED_VEHICLE_TYPE } from './constant';
import {
  SEGWOM0002,
  SEGWOM0003,
  SEGWOM0004,
  SEGWOM0005,
  SEGWOM0006,
  SEGWOM0007,
  SEGW0M0008
} from './manufacturers/segway';
import { YADEYA0001 } from './manufacturers/yadea';
import { DeviceCommandInstruction, DeviceType } from './types';

export function getDeviceType(deviceTypeId?: string): DeviceType {
  return (
    DEVICE_TYPES.find((deviceType) => deviceType.id === deviceTypeId) || UNDEFINED_VEHICLE_TYPE
  );
}

export function hasWirelessChargerSupport(deviceType?: DeviceType): boolean {
  return Boolean(
    deviceType?.supportedActions.find(
      (supportedAction) =>
        supportedAction === DeviceCommandInstruction.wirelessChargerOn ||
        supportedAction === DeviceCommandInstruction.wirelessChargerOff
    )
  );
}

export function hasCableLockSupport(deviceType?: DeviceType): boolean {
  return Boolean(
    deviceType?.supportedActions.find(
      (supportedAction) =>
        supportedAction === DeviceCommandInstruction.cableUnlock ||
        supportedAction === DeviceCommandInstruction.cableLock
    )
  );
}

export function hasAutoLockSupport(deviceType?: DeviceType): boolean {
  return Boolean([YADEYA0001.id].includes(deviceType?.id || ''));
}

export function hasVoiceConfigSupport(deviceType: string): boolean {
  return [SEGWOM0005.id, SEGWOM0006.id, SEGWOM0007.id].includes(deviceType);
}

export function usesSegwayAirInterfaceProtocol(deviceType: string): boolean {
  return [SEGWOM0006.id, SEGWOM0007.id, SEGW0M0008.id].includes(deviceType);
}

export function usesSegwayGen3Protocol(deviceType: string): boolean {
  return SEGWOM0005.id === deviceType;
}

export function usesSegwayGen1Protocol(deviceType: string): boolean {
  return [SEGWOM0002.id, SEGWOM0003.id, SEGWOM0004.id].includes(deviceType);
}
